import type { ComponentProps } from 'react';
import React, { useCallback, useState } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { ConvertToFolderSpotlight } from '@confluence/folder-onboarding';
import type { FormattedWebItem } from '@confluence/web-item-location';
import { useSkippableCoordination } from '@confluence/skippable-coordination-client';
import type { DialogsStateContainer } from '@confluence/dialogs';
import { ConvertPageToFolderDialog } from '@confluence/folder/entry-points/ConvertPageToFolderDialog';
import { useCanConvertPageToFolder } from '@confluence/folder/entry-points/useCanConvertPageToFolder';

import { LazyContentToolsItem } from './LazyContentToolsItem';

export const ConvertToFolderItem = ({
	sectionItem,
	contentId,
	contentType,
	spaceKey,
	dialogs,
	...props
}: ConvertToFolderItemProps) => {
	const [isSpotlightOpen, setIsSpotlightOpen] = useState(true);
	const isFolderConversionOnboardingEnabled = FeatureGates.checkGate(
		'confluence_frontend_convert_to_folder_onboarding',
	);

	const [isConvertToFolderOnboardingActive, stopConvertToFolderOnboarding] =
		useSkippableCoordination(
			'cc-convert-to-folder-onboarding',
			!isFolderConversionOnboardingEnabled,
		);

	const handleSpotlightClose = useCallback(() => {
		setIsSpotlightOpen(false);
		void stopConvertToFolderOnboarding();
	}, [stopConvertToFolderOnboarding]);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const openDialog = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'convertPageToFolder',
				source: 'contentToolsItem',
			},
		}).fire();
		dialogs.showDialog(ConvertPageToFolderDialog, {
			contentId,
			spaceKey,
		});
	};

	const { canConvertPageToFolder } = useCanConvertPageToFolder({
		contentId,
		spaceKey,
		contentType,
	});

	if (!canConvertPageToFolder) return null;

	sectionItem.url = '';
	sectionItem.urlWithoutContextPath = '';

	const convertToFolderItem = (
		<LazyContentToolsItem
			key={sectionItem.completeKey}
			{...sectionItem}
			{...props}
			onClick={openDialog}
		/>
	);
	if (isFolderConversionOnboardingEnabled && isConvertToFolderOnboardingActive && isSpotlightOpen) {
		return (
			<ConvertToFolderSpotlight onClose={handleSpotlightClose}>
				{convertToFolderItem}
			</ConvertToFolderSpotlight>
		);
	}
	return convertToFolderItem;
};

type ConvertToFolderItemProps = {
	sectionItem: FormattedWebItem;
	contentId: string;
	spaceKey: string;
	contentType: string;
	dialogs: DialogsStateContainer;
} & Partial<ComponentProps<typeof LazyContentToolsItem>>;
