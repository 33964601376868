import { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { isExpectedApolloError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import type {
	ConvertPageToFolderBannerQuery as ConvertPageToFolderBannerQueryType,
	ConvertPageToFolderBannerQueryVariables,
} from './__types__/ConvertPageToFolderBannerQuery';
import { ConvertPageToFolderBannerQuery } from './ConvertPageToFolderBannerQuery.graphql';

export const useCanConvertPageToFolder = ({
	contentId,
	spaceKey,
	contentType,
	skipQuery,
}: {
	contentId: string;
	spaceKey: string;
	contentType: string;
	skipQuery?: boolean;
}) => {
	const [canConvertPageToFolder, setCanConvertPageToFolder] = useState(false);

	const isFolderConversionEnabled = FeatureGates.checkGate(
		'confluence_enable_page_to_folder_conversion',
	);

	const { loading, error } = useQuery<
		ConvertPageToFolderBannerQueryType,
		ConvertPageToFolderBannerQueryVariables
	>(ConvertPageToFolderBannerQuery, {
		skip: skipQuery || !isFolderConversionEnabled || contentType !== 'page',
		variables: { contentId, spaceKey },
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			const isFoldersEnabled =
				data?.space?.spaceTypeSettings?.enabledContentTypes?.isFoldersEnabled;
			const contentNode = data?.content?.nodes?.[0];

			const canEdit =
				(contentNode?.operations?.filter(
					(operationCheckResult: any) =>
						operationCheckResult?.operation === 'update' &&
						operationCheckResult?.targetType === 'page',
				)?.length || 0) > 0;

			const isParent = (contentNode?.children?.count || 0) > 0;
			const isContentBodyEmpty = contentNode?.body?.storage?.value === '';

			setCanConvertPageToFolder(
				(isFoldersEnabled && canEdit && isParent && isContentBodyEmpty) || false,
			);
		},
		onError: (error) => {
			if (isExpectedApolloError(error)) {
				markErrorAsHandled(error);
			}
		},
	});

	return { loading, error, canConvertPageToFolder, setCanConvertPageToFolder };
};
